import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '@components/Layout'
import Hero from '@components/Hero'
import ContentWrapper from '@components/ContentWrapper'
import Outline from '@components/Outline'
import unified from 'unified'
import rehypeSlug from 'rehype-slug'
import rehypeReact from '../util/hastRenderer'
import Slugger from 'github-slugger'
import styled from 'styled-components'
import { relativeTime, absoluteTime } from '../util/time'
import theme from '../../config/theme'
import { graphql } from 'gatsby'

const processor = unified()
  .use(rehypeSlug)
  .use(rehypeReact)

const compileAst = (ast) => processor.stringify(processor.runSync(ast))

const transformHeadings = (headings) => {
  const slugger = new Slugger()
  return headings.map((heading) => ({
    text: heading.value,
    level: heading.depth,
    slug: slugger.slug(heading.value)
  }))
}

const HeadSubWrapper = styled.div`
  /* margin-top: -2rem;
  margin-bottom: 2rem; */
  /* position: absolute; */
  /* bottom: 3rem; */

  color: ${theme.colors.light.muted.color};
`

const BlogPostHead = ({title, date}) =>
  <>
    <Hero>
      <h1>{title}</h1>
      {/* {date} */}
      <HeadSubWrapper>
        Published {relativeTime(date)}
      </HeadSubWrapper>
    </Hero>
  </>

const BlogPost = ({data}) => {
  let post = data.markdownRemark
  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <BlogPostHead title={post.frontmatter.title} date={post.frontmatter.date} />
      <ContentWrapper siderail={post.frontmatter.outline}>
        {compileAst(post.htmlAst)}
        {post.frontmatter.outline &&
          <Outline headings={transformHeadings(post.headings)} spy={true} />
        }
      </ContentWrapper>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPageQuery(
    $slug: String!,
    $outline: Boolean!
  ) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      htmlAst
      excerpt
      frontmatter {
        title
        date
        outline
      }

      headings @include(if: $outline) {
        value
        depth
      }
    }
  }
`
